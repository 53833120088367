<template>
  <AxiosLoader/>
  <div class="container">
    <div class="row">
      <div class="col">
        <nav v-show="isAuthenticated" class="navbar navbar-expand-lg bg-light">
          <div class="container-fluid">

            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText"
                    aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarText">

              <ul class="navbar-nav me-auto mb-2 mb-lg-0">

                <li class="nav-item" v-show="availableReports.includes(reportNames.AUTHORS_SALES)">
                  <router-link active-class="active" class="nav-link" to="/">Отчет "Продажи курсов"</router-link>
                </li>

                <li class="nav-item" v-show="availableReports.includes(reportNames.MARKETERS_SALES)">
                  <router-link active-class="active" class="nav-link" to="/marketing-sales-report">Отчет для
                    маркетологов "Продажи курсов"
                  </router-link>
                </li>

                <li class="nav-item" v-show="availableReports.includes(reportNames.MARKETERS_SALES)">
                  <router-link active-class="active" class="nav-link" to="/marketing-active-users-report">Отчет для
                    маркетологов "Активные пользователи"
                  </router-link>
                </li>

              </ul>

              <span v-show="isAuthenticated" class="navbar-text">
                User #{{ user?.id }}
                <a @click="logout" href="#">Log Out</a>
              </span>

            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>

  <router-view/>

</template>

<script>
// import {mapGetters} from "vuex";
// import axiosClient from './services/axios';
import AxiosLoader from "@/components/AxiosLoader";
import {DO_LOGOUT} from '@/store/auth/types'
import {mapGetters} from 'vuex';
import {
  ACTIVE_USERS,
  AUTHORS_SALES,
  MARKETERS_SALES,
  DEBUG
} from './services/report-constants'

export default {
  name      : 'App',
  components: {
    AxiosLoader
  },
  data() {
    return {
      reportNames: {
        ACTIVE_USERS   : ACTIVE_USERS,
        AUTHORS_SALES  : AUTHORS_SALES,
        MARKETERS_SALES: MARKETERS_SALES,
        DEBUG          : DEBUG,
      }
    };
  },

  computed: {
    ...mapGetters( {
          isAuthenticated : 'auth/isAuthenticated',
          user            : 'auth/user',
          availableReports: 'auth/availableReports',
        }
    )
  },

  methods: {
    async logout() {
      await this.$store.dispatch( `auth/${ DO_LOGOUT }` )
      this.$router.push( '/login' )
    }
  }
}
</script>

<style lang="stylus">
@import '~bootstrap/dist/css/bootstrap.css';
</style>
