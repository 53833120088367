<template>
  <!--  <td>{{ item }}</td>-->
  <td>{{ item.date }}</td>
  <td class="text-end text-bg-light border-start">
    {{ item.data?.activeUsers?.uniqUsers?.length || 0 }}
  </td>
  <td class="text-end text-bg-light">
    <span class="text-muted">данные будут доступны после интеграции с метрикой</span>
  </td>
  <td class="text-end text-bg-light border-end">{{
      item.data?.activeUsers?.lessonsWatchDurationSeconds || 'нет данных'
    }}
  </td>
  <td class="text-end border-start">{{ item.data?.usersRegistered?.length || '-' }}</td>
  <td class="text-end border-end">
    <p :key="utmOne" v-for="utmOne in deduplicate(item.data?.usersRegistered, 'userSignUpUtm')">
      {{ utmOne }}
    </p>
  </td>
  <td class="text-end text-bg-light border-start">{{ item.data?.purchasesMade?.allPurchases?.length || '-' }}</td>
  <td class="text-end text-bg-light">

    <p :key="utmOne" v-for="utmOne in deduplicate(item.data?.purchasesMade?.allPurchases, 'utm')">
      {{ utmOne }}
    </p>

    <p :key="utmOne"
          v-for="utmOne in deduplicate(item.data?.purchasesMade?.allPurchases, 'coursePurchaseAnalyticsUtm')">
      {{ utmOne }}
    </p>

  </td>

  <!--  //TODO: REFACTOR...THIS IS UGLY -->
  <td class="text-end text-bg-light ">{{
      formatPrice( item.data?.purchasesMade?.allPurchases.reduce( ( amt, item ) => {
        return amt + item.course.price || item.course.price_override
      }, 0 ) ) || '-'
    }}
  </td>
  <td class="text-bg-light ">

    <span :key="categoryOne"
          v-for="categoryOne in deduplicate(item.data?.purchasesMade?.allPurchases, 'course.categories')">
       · {{ categoryOne.title }}
    </span>

  </td>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'ActiveUsersReportGridRowReadOnly',

  props: ['item'],

  methods: {
    formatDate( date ) {
      // TODO: move to filters
      if (!date) {
        return 'нет информации о дате'
      }

      return new Intl.DateTimeFormat( 'ru-RU', { dateStyle: 'short' } ).format( new Date( date ) )
    },

    deduplicate( arr, path ) {
      var mapped = _.flatMapDeep( arr, path );
      var uniq = _.uniqWith( mapped, _.isEqual );
      return uniq;
    },

    formatPrice( number ) {
      // TODO: move to filters
      if (typeof number === 'undefined') {
        return '-'
        // return 'нет информации о стоимости'
      }

      if (number === 0) return number;

      return new Intl.NumberFormat( 'ru-RU', { style: 'currency', currency: 'RUB' } ).format( number );
    },

    getAge( number ) {
      const getAge = birthDate => Math.floor( (new Date() - new Date( birthDate ).getTime()) / 3.15576e+10 );

      if (!number) return 0;

      return getAge( number );
    }
  },

  mounted() {

  }
}
</script>
