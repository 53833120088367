<template>
  <div v-if="distinctItems.length > 0" class="card">
    <h6 class="card-header">
      <slot name="header-title">
        Фильтр
      </slot>
    </h6>

    <div v-show="distinctItems.length" class="card-body" style="max-height: 200px; overflow-y: scroll">

      <div class="form-check">
        <input class="form-check-input" type="checkbox" :id="`${prefix}_${$.uid}_all-items`"
               :checked="allItemsSelected" @click="toggleAllItemsSelected"/>
        <label class="form-check-label" :for="`${prefix}_${$.uid}_all-items`">
          Выбрать все
        </label>
      </div>

      <div class="form-check" v-for="(item, index) in distinctItems" :key="item">
        <input class="form-check-input" type="checkbox" :value="item" :id="getUniqId(item, index)"
               v-model="selectedItems">
        <label class="form-check-label" :for="getUniqId(item, index)">
          <slot name="item-label" :item="item">
            {{ item }}
          </slot>
        </label>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name      : 'DistinctItemsCheckboxReportFilter',
  components: {},
  emits     : ['filterChanged'],
  props     : ['distinctItems', 'prefix'],

  data() {
    return {
      selectedItems: [],
    }
  },

  computed: {
    allItemsSelected() {
      return this.selectedItems.length === this.distinctItems.length;
    },
  },

  watch: {
    distinctItems: {
      immediate: true,
      handler  : function ( newVal ) {
        this.selectedItems = newVal || [];
      }
    },

    selectedItems: {
      immediate: true,
      handler  : function () {
        this.$emit( 'filter-changed', this.selectedItems )
      }
    },
  },

  methods: {

    getUniqId( item, index ) {
      const template = `${this.prefix}_${ this.$.uid }_distinct_item_${ index }_`;

      if (typeof item?.id !== "undefined") {
        return template + item.id
      }

      return template + item;
    },

    toggleAllItemsSelected() {

      if (this.allItemsSelected === false) {
        this.selectedItems = this.distinctItems;
        return;
      }

      if (this.allItemsSelected === true) {
        this.selectedItems = [];
        return;
      }
    },

    mounted() {
      this.selectedItems = this.distinctItems;
    },
  }
}
</script>
