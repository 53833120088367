<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <!--        <h1>Отчет для маркетологов "Активные пользователи"</h1>-->

        <div class="row">
          <div class="col text-start">
            <div class="card">
              <div class="card-header">
                Параметры
              </div>
              <div class="card-body">

                <div class="row">
                  <div class="col-12 col-md-2 pb-3">
                    <p><strong>Дата</strong></p>
                    <Datepicker v-model="reportDateRange"
                                weekStart="1"
                                range
                                auto-apply
                                showNowButton
                                :enableTimePicker="false"
                                :format="reportDateRangeFormat"
                    />
                  </div>
                  <div class="col-12 col-md-4 px-3 pb-3">

                    <DistinctItemsCheckboxReportFilter :prefix="'course_categories'" :distinctItems="distinctCategories"
                                                       @filter-changed="courseCategorieseFilterChanged">
                      <template v-slot:header-title>
                        Категории
                      </template>
                      <template #item-label="props">
                        {{ props?.item?.attributes?.title }}
                        <span class="text-muted">#{{ props?.item?.id }}</span>
                      </template>
                    </DistinctItemsCheckboxReportFilter>

                  </div>
                  <div class="col-12 col-md-4 pb-3">
                    <p><strong>Стоимость</strong></p>


                    <div class="form-check" v-for="item in freeOrPaidCoursesFilterItems" :key="item.key">
                      <input class="form-check-input" type="radio" name="freeOrPaidCourseFilter" :value="item.key"
                             :id="'freeOrPaid_' + item.key"
                             v-model="reportParameters.limitToFreeOrPaidCourses">
                      <label class="form-check-label" :for="'freeOrPaid_' + item.key">
                        {{ item.title }}
                      </label>
                    </div>

                  </div>
                </div>
              </div>
              <div class="card-footer">

                <button v-if="isReportParametersValid.valid" @click="searchReportItems"
                        class="btn btn-primary">Поиск
                </button>

                <p class="text-danger p-0 m-0">{{ isReportParametersValid.errors }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">

          <div class="col">
            <DistinctItemsCheckboxReportFilter :prefix="'course_title'" :distinctItems="distinctCourseTitles"
                                               @filter-changed="courseTitleFilterChanged">
              <template v-slot:header-title>
                Курсы
              </template>
              <template #item-label="props">
                {{ props?.item?.title }}
              </template>
            </DistinctItemsCheckboxReportFilter>
          </div>

          <div class="col">
            <DistinctItemsCheckboxReportFilter :prefix="'utm_filter'" :distinctItems="distinctUtmTagsFromItems"
                                               @filter-changed="utmTagsFilterChanged">
              <template v-slot:header-title>
                UTM
              </template>
              <template #item-label="props">
                {{ props.item ? props?.item : '(null)' }}
              </template>
            </DistinctItemsCheckboxReportFilter>
          </div>

        </div>

        <div class="row py-4">
          <div class="col">
            <ActiveUsersReportGrid :items="filteredReportItems"/>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>


<script>
// @ is an alias to /src
import '@vuepic/vue-datepicker/dist/main.css'
import Datepicker from '@vuepic/vue-datepicker';
import ActiveUsersReportGrid from "@/components/ActiveUsersReportGrid";
import {mapGetters} from 'vuex';
import _ from 'lodash';
import activeUsersReportService from "@/services/active-users-report";
import DistinctItemsCheckboxReportFilter from "@/components/DistinctItemsCheckboxReportFilter";


export default {
  name: 'MarketingSalesView',

  components: {
    ActiveUsersReportGrid,
    DistinctItemsCheckboxReportFilter,
    Datepicker
  },

  data() {
    return {
      sortedReportItems: [],

      reportParameters: {
        from                    : null,
        to                      : null,
        limitToFreeOrPaidCourses: 'BOTH',
        limitToCategories       : []
      },

      reportDateRange: null,

      freeOrPaidCoursesFilterItems: [
        { key: 'BOTH', title: 'Все' },
        { key: 'FREE', title: 'Бесплатные' },
        { key: 'PAID', title: 'Платные' },
      ],

      reportFilters: {
        allCategoriesToggle: true,
        utmTagsFilters     : [],
        courseTitles       : []
      }

    }
  },


  computed: {

    distinctCourseTitles() {
      var a = _( this.sortedReportItems )
          .flatMap( 'data' )
          .flatMap( 'purchasesMade' )
          .flatMap( 'allPurchases' )
          .flatMap( 'course' )
          .uniqBy( 'id' )
          .sortBy( 'title' )
          .value();

      return a;
    },

    distinctUtmTagsFromItems() {

      var userSignUpUtmArr2 = _( this.sortedReportItems )
          .flatMap( 'data' )
          .flatMap( 'usersRegistered' )
          .flatMap( 'userSignUpUtm' ).compact().value();

      var purchasesUtmArr2 = _( this.sortedReportItems )
          .flatMap( 'data' )
          .flatMap( 'purchasesMade' )
          .flatMap( 'allPurchases' )
          .flatMap( 'utm' )
          .compact().value();

      var purchasesAnalyticsUtmArr2 = _( this.sortedReportItems )
          .flatMap( 'data' )
          .flatMap( 'purchasesMade' )
          .flatMap( 'allPurchases' )
          .flatMap( 'coursePurchaseAnalyticsUtm' )
          .compact().value()

      let flatted = _.flattenDeep( [
            userSignUpUtmArr2,
            purchasesUtmArr2,
            purchasesAnalyticsUtmArr2
          ]
      );

      let uniq = _.uniqWith( flatted, _.isEqual );
      let sorted = _.sortBy( uniq, ['utm_campaign', 'utm_medium', 'utm_source'] )

      if (sorted.length) {
        sorted.push( null )
      }

      return sorted;
    },

    isReportParametersValid() {
      if (!this.reportParameters.from) return { valid: false, errors: ['Выберите дату'] };
      if (!this.reportParameters.to) return { valid: false, errors: ['Выберите дату'] };
      if (this.reportParameters.limitToCategories.length === 0) return { valid: false, errors: ['Выберите категории'] };

      return { valid: true };
    },

    filteredReportItems() {
      // if (this.reportFilters.utmTagsFilters.length === 0) return this.sortedReportItems;

      var cloned = _.cloneDeep( this.sortedReportItems );

      var filterUsersRegistered = ( usersRegistered = [] ) => {
        return _.filter( usersRegistered, x => {
          // let intersection = _.intersectionBy( _.flattenDeep( [x?.userSignUpUtm], _.isEqual ), this.reportFilters.utmTagsFilters );
          var intersection = _.find( this.reportFilters.utmTagsFilters, a => _.find( _.flattenDeep( [x?.userSignUpUtm] ), b => _.isEqual( a, b ) ) );

          return !!intersection;
        } );
      };

      var filterPurchasesMade = ( allPurchases = [] ) => {

        var utm1 = _.filter( allPurchases, ap => {
              var found = _.find( this.reportFilters.utmTagsFilters, f => _.isEqual( f, ap.utm ) );
              if (typeof found !== 'undefined') return ap;
            }
        )

        var utm2 = _.filter( allPurchases, ap => {
              var manyUtms = _.flattenDeep( [ap?.coursePurchaseAnalyticsUtm] );
              var filtered = _.filter( manyUtms, m => this.reportFilters.utmTagsFilters.includes( m ) );
              if (filtered.length > 0) return ap;
            }
        );

        var filteredByUtm = _.flattenDeep( [utm1, utm2] );

        var filteredByTitle = _.filter( filteredByUtm, ap => _.find( this.reportFilters.courseTitles, a => _.isEqual( a?.id, ap?.course?.id ) ) );

        return filteredByTitle;
      };

      return _.map( cloned, item => {
            let filteredUsersRegistered = filterUsersRegistered( item?.data?.usersRegistered );
            let filteredPurchasesMade = filterPurchasesMade( item?.data?.purchasesMade?.allPurchases );

            _.set( item, 'data.usersRegistered', filteredUsersRegistered );
            _.set( item, 'data.purchasesMade.allPurchases', filteredPurchasesMade );

            return item;
          }
      );

    },

    ...mapGetters( {
          // sortedReportItems : 'main/sortedActiveUsersReportItems',
          distinctCategories: 'main/distinctCategories',
        }
    )
  },

  watch: {

    reportDateRange( newVal = {} ) {
      if (!newVal) {
        this.reportParameters.from = null;
        this.reportParameters.to = null;
        return;
      }

      var [from, to] = newVal;

      this.reportParameters.from = from;
      this.reportParameters.to = to;
    }
  },

  methods: {
    courseCategorieseFilterChanged( val ) {
      this.reportParameters.limitToCategories = val;
    },

    utmTagsFilterChanged( val ) {
      this.reportFilters.utmTagsFilters = val;
    },

    courseTitleFilterChanged( val ) {
      console.log( val );
      this.reportFilters.courseTitles = val;
    },

    reportDateRangeFormat( date ) {
      var [from, to] = date;
      const formatDate = ( date ) => new Intl.DateTimeFormat( 'ru-RU' ).format( date );
      return [`${ formatDate( from ) } - ${ formatDate( to ) }`];
    },

    resetReport() {
      this.sortedReportItems = [];
    },

    async searchReportItems() {
      this.resetReport();

      var response = await activeUsersReportService.find( {
        dateRange               : {
          dateFrom: this.reportParameters.from,
          dateTo  : this.reportParameters.to,
        },
        limitToCategories       : this.reportParameters.limitToCategories,
        limitToFreeOrPaidCourses: this.reportParameters.limitToFreeOrPaidCourses
      } );

      this.sortedReportItems = (response?.data || []);
    },
  },

  async beforeMount() {
    await this.$store.dispatch( "main/getAllCategories" );
    this.reportParameters.limitToCategories = this.distinctCategories;
    this.reportFilters.utmTagsFilters = this.distinctUtmTagsFromItems;
    this.reportFilters.courseTitles = this.distinctCourseTitles;
  },

  mounted() {
  }
}
</script>
