<template>

  <table class="table table-sm align-top">
    <thead class="align-top table-info sticky-top">
    <tr>
      <th scope="col">Дата покупки</th>
      <th scope="col">Студент</th>
      <th scope="col">Пол</th>
      <th scope="col">Возраст</th>
      <th scope="col">Платные / Бесплатные</th>
      <th scope="col">Источник покупок (UTM)</th>
      <th scope="col">Категория</th>
      <th scope="col">Автор курса</th>
      <th scope="col">Навзвание курса</th>
      <th scope="col">Просмотрено уроков</th>
      <th scope="col">Оставлено комментариев</th>
<!--      <th scope="col"></th>-->
    </tr>
    </thead>
    <tbody>

    <tr v-show="reportItemsLocalCopy.length == 0">
      <td colspan="10">
        Нет данных. Выберите диапазон дат.
      </td>
    </tr>

    <tr v-for="item in reportItemsLocalCopy" :key="item.id">
      <MarketingSalesReportGridRowReadOnly :item="item"/>
    </tr>

<!--    <tr class="table-light" v-show="reportItemsLocalCopy.length">-->
<!--      <td>Итого</td>-->
<!--      <td></td>-->
<!--      <td></td>-->
<!--      <td></td>-->
<!--      <td>{{ totalSales }} руб</td>-->
<!--      <td></td>-->
<!--      <td></td>-->
<!--    </tr>-->
    </tbody>
  </table>
</template>

<script>
// import {mapState, mapGetters} from 'vuex';
import MarketingSalesReportGridRowReadOnly from "@/components/MarketingSalesReportGridRowReadOnly";

export default {
  name: 'MarketingSalesReportGrid',

  props: ['items', 'totalSales'],

  data() {
    return {
      reportItemsLocalCopy: [],
    };
  },

  components: {
    MarketingSalesReportGridRowReadOnly,
  },

  methods: {},

  watch: {
    items: {
      immediate: true,
      handler  : function ( newVal ) {
        this.reportItemsLocalCopy = newVal || [];
      }
    }
  },

  mounted() {
    this.reportItemsLocalCopy = this.items || [];
  }
}
</script>

