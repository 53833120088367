import client from './../axios';
import qs from 'qs';

async function find( { dateRange, limitToCategories, limitToFreeOrPaidCourses } ) {

    if (!dateRange) {
        dateRange = {
            dateFrom: new Date(),
            dateTo  : new Date()
        };
    }

    var format = ( date ) => {

        const month = date.toLocaleDateString( 'en-US', { month: '2-digit' } );
        const day = date.toLocaleDateString( 'en-US', { day: '2-digit' } );
        const year = date.getFullYear();

        return `${ year }-${ month }-${ day }`;
    }

    var { dateFrom, dateTo } = dateRange;

    var from = format( dateFrom );
    var to = format( dateTo );

    var query = qs.stringify( {
        filters: {
            $and             : [
                { createdAt: { $gte: from } },
                { createdAt: { $lte: to } },
            ],
            limitToCategories: limitToCategories.map( x => x.id ),
            limitToFreeOrPaidCourses: limitToFreeOrPaidCourses || 'BOTH'
        },
    }, {
        encodeValuesOnly: true,
    } );

    var response = await client.get( `/api/reporting/marketers/active-users${ query ? `?${ query }` : '' }` );

    return response.data;
}

export default { find };
