<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="row">
          <div class="col text-start">
            <div class="card">
              <div class="card-header">
                Период
              </div>
              <div class="card-body">
                <Datepicker v-model="reportDateRange"
                            weekStart="1"
                            range
                            auto-apply
                            showNowButton
                            :enableTimePicker="false"
                            :format="reportDateRangeFormat"
                />
              </div>
            </div>
          </div>
          <div class="col text-start">
            <DistinctItemsCheckboxReportFilter :prefix="'students'" :distinctItems="distinctUsers"
                                               @filter-changed="usersFilterChanged">
              <template v-slot:header-title>
                Студенты
              </template>
              <template #item-label="props">
                Студент #{{ props?.item?.id }}
              </template>
            </DistinctItemsCheckboxReportFilter>

          </div>

          <div class="col text-start">
            <DistinctItemsCheckboxReportFilter :prefix="'category_title'" :distinctItems="distinctCategories"
                                               @filter-changed="categoriesFilterChanged">
              <template v-slot:header-title>
                Категории
              </template>
              <template #item-label="props">
                {{ props?.item?.title }}
              </template>
            </DistinctItemsCheckboxReportFilter>

          </div>


          <div class="col text-start">
            <DistinctItemsCheckboxReportFilter :prefix="'authors'" :distinctItems="distinctAuthors"
                                               @filter-changed="authorsFilterChanged">
              <template v-slot:header-title>
                Авторы
              </template>
              <template #item-label="props">
                {{ props?.item?.name }}
              </template>
            </DistinctItemsCheckboxReportFilter>

          </div>
          <div class="col text-start">
            <DistinctItemsCheckboxReportFilter :prefix="'course_title'" :distinctItems="distinctCourses"
                                               @filter-changed="coursesFilterChanged">
              <template v-slot:header-title>
                Курсы
              </template>
              <template #item-label="props">
                {{ props?.item?.title }}
              </template>
            </DistinctItemsCheckboxReportFilter>

          </div>

          <div class="col text-start">
            <DistinctItemsCheckboxReportFilter :prefix="'utms'" :distinctItems="distinctUtms"
                                               @filter-changed="utmFilterChanged">
              <template v-slot:header-title>
                UTM
              </template>
              <template #item-label="props">
                {{ props.item ? props?.item : '(null)' }}
              </template>
            </DistinctItemsCheckboxReportFilter>

          </div>
          <div class="col text-start">
            <DistinctItemsCheckboxReportFilter :prefix="'price'" :distinctItems="distinctPrice"
                                               @filter-changed="priceFilterChanged">
              <template v-slot:header-title>
                Стоимость
              </template>
              <template #item-label="props">
                {{ props?.item === 0 ? 'бесплатно' : format( props?.item ) }}
              </template>
            </DistinctItemsCheckboxReportFilter>

          </div>


          <div class="col text-start">
            <DistinctItemsCheckboxReportFilter :prefix="'lesson_view_count'" :distinctItems="distinctLessonViewCount"
                                               @filter-changed="lessonViewCountFilterChanged">
              <template v-slot:header-title>
                Просмотрено уроков
              </template>
              <template #item-label="props">
                {{ props?.item }}
              </template>
            </DistinctItemsCheckboxReportFilter>

          </div>



        </div>
        <div class="row">
        </div>
        <div class="row py-4">
          <div class="col">
            <MarketingSalesReportGrid :items="filteredReportItems" :totalSales="totalSales"/>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>


<script>
// @ is an alias to /src
import '@vuepic/vue-datepicker/dist/main.css'
import Datepicker from '@vuepic/vue-datepicker';
import MarketingSalesReportGrid from "@/components/MarketingSalesReportGrid";
import _ from 'lodash';
import DistinctItemsCheckboxReportFilter from "@/components/DistinctItemsCheckboxReportFilter";
import {formatPrice} from "@/utils/format"
import marketingReportsService from "@/services/marketing-sales-report";

export default {
  name      : 'MarketingSalesView',
  components: {
    MarketingSalesReportGrid,
    DistinctItemsCheckboxReportFilter,
    Datepicker
  },

  data() {
    return {
      sortedReportItems: [],

      reportDateRange: null,

      reportFilters: {
        authors        : [],
        courses        : [],
        users          : [],
        lessonViewCount: [],
        categories     : [],
        utm            : [],
        price          : [],
      }
    }
  },


  computed: {

    totalSales() {

      return _.reduce( this.filteredReportItems, ( sum, item ) => {
        return sum + (item.paymentTransaction?.total || 0)
      }, 0 )

    },

    filteredByUsers() {
      let filtered = _.chain( this.sortedReportItems )
          .filter( item => !!item.user )
          .filter( item => this.reportFilters.users.map( u => u.id ).includes( item.user.id ) )
          .value();

      return filtered;
    },


    filteredByCategories() {
      let filtered = _.chain( this.filteredByUsers )
          .filter( item => !!item.course )
          .filter( item => {
                let categories = item.course.categories;
                const filtered = _.filter( this.reportFilters.categories, cat => _.find( categories, icat => _.isEqual( icat.id, cat.id ) ) );
                if (filtered.length > 0) return item;
              }
          )
          .value();

      return filtered;
    },

    filteredByAuthors() {
      console.time( 'filteredByAuthors' );

      let itemsByAuthors = _.chain( this.filteredByCategories )
          .filter( item => !!item.course )
          .filter( item => !!item.course.author )
          .filter( item => this.reportFilters.authors.map( a => a.id ).includes( item.course.author.id ) )
          .value();
      console.timeEnd( 'filteredByAuthors' );

      return itemsByAuthors;
    },

    filteredByCourses() {
      let itemsByCourses = _.chain( this.filteredByAuthors )
          .filter( item => this.reportFilters.courses.map( co => co.id ).includes( item.course.id ) )
          .value();

      return itemsByCourses;
    },

    filteredByUtm() {
      let filtered = _.chain( this.filteredByCourses )
          .filter( item => this.reportFilters.utm.includes( item.utm ) )
          .value();

      return filtered;
    },

    filteredByPrice() {
      let filtered = _.chain( this.filteredByUtm )
          .filter( item => !!item.course )
          .filter( item => this.reportFilters.price.includes( item.course.price ) )
          .value();

      return filtered;
    },


    filteredByLessonViewCount() {
      let itemsByCourses = _.chain( this.filteredByPrice )
          .filter( item => this.reportFilters.lessonViewCount.includes( _.get( item, 'course.progress.lessonsCompleted' ) ) )
          .value();

      return itemsByCourses;
    },



    filteredReportItems() {
      return this.filteredByLessonViewCount;
    },

    distinctUsers() {

      let items = _.chain( this.sortedReportItems )
          .map( 'user' )
          .uniqBy( 'id' )
          .sortBy( 'id' )
          .value();

      return items;
    },

    distinctCategories() {
      let items2 = _.chain( this.filteredByUsers )
          .filter('course')
          .flatMap( 'course' )
          .flatMap( 'categories' )
          .uniqBy( 'id' )
          .sortBy( 'title' )
          .value();

      return items2;
    },

    distinctAuthors() {

      let items2 = _.chain( this.filteredByCategories )
          .map( 'course.author' )
          .uniqBy( 'id' )
          .sortBy( 'name' )
          .value();

      return items2;
    },

    distinctCourses() {
      let items2 = _.chain( this.filteredByAuthors )
          .map( 'course' )
          .uniqBy( 'id' )
          .sortBy( 'title' )
          .value();

      return items2;
    },

    distinctUtms() {
      let items2 = _.chain( this.filteredByCourses )
          .map( 'utm' )
          .uniq()
          .value();

      return items2;
    },

    distinctPrice() {
      let items2 = _.chain( this.filteredByUtm )
          .map( 'course.price' )
          .uniq()
          .value();

      return items2;
    },

    distinctLessonViewCount() {
      let items2 = _.chain( this.filteredByPrice )
          .map( 'course.progress.lessonsCompleted' )
          .uniq()
          .value();

      return items2;
    },

  },

  watch: {
    sortedReportItems: {
      immediate: true,
      handler  : function () {
        this.reportFilters.users = this.distinctUsers;
        this.reportFilters.authors = this.distinctAuthors;
        this.reportFilters.courses = this.distinctCourses;
      }
    },

    reportDateRange( newVal ) {
      var [from, to] = newVal;
      this.searchSalesReportItems( from, to );
    }
  },

  methods: {

    format( number ) {
      return formatPrice( number )
    },

    usersFilterChanged( val ) {
      this.reportFilters.users = val;
    },
    priceFilterChanged( val ) {
      this.reportFilters.price = val;
    },
    utmFilterChanged( val ) {
      this.reportFilters.utm = val;
    },
    categoriesFilterChanged( val ) {
      this.reportFilters.categories = val;
    },

    authorsFilterChanged( val ) {
      this.reportFilters.authors = val;
    },
    coursesFilterChanged( val ) {
      this.reportFilters.courses = val;
    },
    lessonViewCountFilterChanged( val ) {
      this.reportFilters.lessonViewCount = val;
    },

    reportDateRangeFormat( date ) {
      var [from, to] = date;
      const formatDate = ( date ) => new Intl.DateTimeFormat( 'ru-RU' ).format( date );
      return [`${ formatDate( from ) } - ${ formatDate( to ) }`];
    },

    resetReport() {
      this.sortedReportItems = [];
    },

    async searchSalesReportItems( dateFrom, dateTo ) {
      this.resetReport();
      var response = await marketingReportsService.find( { dateFrom, dateTo } );
      this.sortedReportItems = (response?.data || []);

      // this.$store.dispatch( 'main/getMarketingSalesReportItems', { dateRange: { dateFrom, dateTo } } )
    },
  },

  mounted() {
  }
}
</script>
