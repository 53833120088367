import _ from 'lodash';

import categoriesService from '@/services/categories';

import {
    ALERT_ON_FAILURE,
    CATEGORIES_FETCHED
} from './types';

const MainStore = {
    namespaced: true,

    state: () => ({
        alerts    : [],
        categories: [],
    }),

    getters: {
        distinctCategories( state ) {
            return _.sortBy( state.categories, 'attributes.title' );
        },
        alerts( state ) {
            return state.alerts;
        },
    },

    mutations: {

        [CATEGORIES_FETCHED]( state, payload ) {
            state.categories = (payload?.data || []);
        },

        [ALERT_ON_FAILURE]( state, payload ) {
            if (payload) {
                state.alerts.push( payload );
            } else {
                state.alerts = [];
            }
        },

        ['RESET_STATE']( state ) {
            state.alerts = [];
            state.categories = [];
        }
    },

    actions: {

        async ['RESET_STATE']( { commit } ) {
            commit( 'RESET_STATE' );
        },

        async getAllCategories( ctx ) {
            var { commit } = ctx;

            var response = await categoriesService.find();
            commit( CATEGORIES_FETCHED, response );
        },

    },
};


export default MainStore;
