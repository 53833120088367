<template>
  <div v-if="isLoading">
    <div class="alert alert-warning position-fixed d-flex justify-content-center align-items-center"
         style="z-index: 9999; min-height: 100vh; min-width:100vw">

      <p class="text" v-html="text"></p>
    </div>
  </div>

  <div v-if="isError">
    <div class="alert alert-danger sticky-top">
      <p class="text" v-html="errorText"></p>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import axiosClient from '@/services/axios';

export default {
  name: 'AxiosLoader',

  data() {
    return {
      isLoading: false,
      text     : "Загружаем...",
      isError  : false,
      errorText: '',
    };
  },

  computed: {
    ...mapGetters(
        {
          // alerts: 'main/alerts',
        }
    )
  },

  methods: {

    showErrors( errors = [] ) {
      this.isError = true;
      this.errorText = errors.join( ' | ' );
    },

    resetErrors() {
      this.errorText = '';
      this.isError = false;
    },

    enableInterceptor() {
      // Add a request interceptor
      axiosClient.interceptors.request.use( ( config ) => {
        // Do something before request is sent

        this.resetErrors();
        this.isLoading = true;

        return config;
      }, ( error ) => {
        // Do something with request error
        this.isLoading = false;
        this.showErrors( [error?.message] )

        return Promise.reject( error );
      } );

      // Add a response interceptor
      axiosClient.interceptors.response.use( ( response ) => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        this.isLoading = false;

        return response;
      }, ( error ) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        var genericErrorMessage = error?.message;
        var apiResponse = error?.response?.data;

        var errors = [
          genericErrorMessage,
          apiResponse?.error?.message
        ];

        this.isLoading = false;

        this.showErrors( errors );

        return Promise.reject( error );
      } );
    },
  },

  mounted() {
    this.enableInterceptor()
  },
}
</script>
