import client from './../axios';
import qs from 'qs';

async function find( { userIdOrEmail } ) {

    var query = qs.stringify( {
        filters: {
            userIdOrEmail
        },
    }, {
        encodeValuesOnly: true,
    } );

    var response = await client.get( `/api/reporting/marketers/debug${ query ? `?${ query }` : '' }` );

    return response.data;
}

export default { find };
