import client from './../axios';
import qs from 'qs';

async function find() {

    var query = qs.stringify( {
        pagination: {
            pageSize: 100
        },
    }, {
        encodeValuesOnly: true,
    } );

    var response = await client.get( `/api/categories/${ query ? `?${ query }` : '' }` );

    return response.data;
}

export default { find };
