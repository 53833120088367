// import _ from "lodash";

// import apiClient from './api-client';

import {DO_LOGOUT, DO_LOGIN, PERMISSIONS} from './types'
import {auth, getPermissions} from '@/services/auth';

const AuthStore = {
    namespaced: true,

    state: () => ({
        user            : null,
        availableReports: [],
        jwt             : null
    }),

    mutations: {
        ['AUTH_ON_PERMISSIONS']( state, payload ) {
            state.availableReports = payload.reports;
        },

        ['AUTH_ON_LOGIN']( state, payload ) {
            state.user = payload.user;
            state.jwt = payload.jwt;
        },

        ['AUTH_ON_LOGOUT']( state ) {
            state.user = null;
            state.jwt = null;
        },

        ['RESET_STATE']() {

        },

    },

    actions: {
        async [PERMISSIONS]( { commit } ) {
            var data = await getPermissions();
            commit( 'AUTH_ON_PERMISSIONS', data );
        },

        async [DO_LOGIN]( { commit, dispatch }, payload ) {
            // make ajax with payload
            var data = await auth( payload );
            commit( 'AUTH_ON_LOGIN', { user: { id: data.user.id, role: data.user.role }, jwt: data.jwt } )

            await dispatch( PERMISSIONS ); // TODO: With this implementation, if more reports available after login - user must re-login
        },

        async [DO_LOGOUT]( { commit, dispatch } ) {
            commit( 'AUTH_ON_LOGOUT' );

            dispatch( 'auth/RESET_STATE', null, { root: true } );
            dispatch( 'main/RESET_STATE', null, { root: true } );
        },

        async ['RESET_STATE']( { commit } ) {
            commit( 'RESET_STATE' );
        },


    },

    getters: {
        isAuthenticated   : state => !!state.jwt,
        user              : state => state.user,
        availableReports  : state => state.availableReports,
        jwt               : state => state.jwt,
    },
};


export default AuthStore;
