<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1>Raw Report</h1>
        <div class="row">
          <div class="col-4 text-start">
            <div class="card">
              <div class="card-header">
                id или email
              </div>
              <div class="card-body">
                <form @submit.prevent="searchReportItems">
                  <input class="form-control" type="text" v-model="userIdOrEmail">
                  <br>
                  <button type="submit" class=" btn btn-primary">Поиск
                  </button>
                </form>

              </div>
              <div class="card-footer">

              </div>
            </div>
          </div>

        </div>

        <div class="row py-4">
          <div class="col" v-if="reportData">
            <h4>User</h4>
            <p>{{ reportData?.user }}</p>

            <h4>Profile</h4>
            <p>{{ reportData?.userProfile }}</p>

            <h4>Transactions</h4>
            <p :key="tran" v-for="tran in reportData?.userTransactions">
              {{ tran }}
            </p>

            <h4>User Purchases</h4>
            <p :key="purch" v-for="purch in reportData?.userPurchases">
              {{ purch }}
            </p>

            <h4>User comments</h4>
            <p :key="comm" v-for="comm in reportData?.userComments">
              {{ comm }}
            </p>

            <h4>Analytics</h4>
            <h5>User signup</h5>
            <p :key="ev" v-for="ev in reportData?.analytics?.userSignupUtmAnalytics">
              {{ ev }}
            </p>

            <h5>Course purchase</h5>
            <p :key="ev" v-for="ev in reportData?.analytics?.coursePurchaseUtmAnalytics">
              {{ ev }}
            </p>

            <h5>Utm History</h5>
            <p :key="ev" v-for="ev in reportData?.analytics?.utmHistoryAnalytics">
              {{ ev }}
            </p>


          </div>
        </div>
      </div>
    </div>
  </div>

</template>


<script>
// @ is an alias to /src
import '@vuepic/vue-datepicker/dist/main.css'
// import Datepicker from '@vuepic/vue-datepicker';
// import ActiveUsersReportGrid from "@/components/ActiveUsersReportGrid";
// import {mapGetters} from 'vuex';
// import _ from 'lodash';
import service from '@/services/debug-report'

export default {
  name: 'RawReportView',

  components: {
    // ActiveUsersReportGrid,
    // Datepicker
  },

  data() {
    return {
      userIdOrEmail: null,
      reportData   : null,
    }
  },


  computed: {},

  watch: {},

  methods: {
    //

    async searchReportItems() {
      this.reportData = await service.find( { userIdOrEmail: this.userIdOrEmail } );
    },
  },

  async beforeMount() {

  },

  mounted() {
  }
}
</script>
