<template>

  <table class="table table-sm align-top">
    <thead class="align-top table-info sticky-top">
    <tr>
      <th scope="col">Дата</th>
      <th scope="col" class="text-end border-start">Кол-во активных пользователей (комментарии, покупки, просмотры видео)</th>
      <th scope="col" class="text-end">Время на сайте (мин.)</th>
      <th scope="col" class="text-end border-end">Просмотрено видео (мин.)</th>
      <th scope="col" class="text-end border-start">Количество регистраций</th>
      <th scope="col" class="text-end border-end">Источник регистраций (UTM)</th>
      <th scope="col" class="text-end border-start">Добавлено / Куплено курсов</th>
      <th scope="col" class="text-end border-start">Источник покупок (UTM)</th>
      <th scope="col" class="text-end">Сумма оплат</th>
      <th scope="col" class="w-25">Категория</th>
    </tr>
    </thead>
    <tbody>

    <tr v-show="reportItemsLocalCopy.length == 0">
      <td colspan="10">
        Нет данных.
      </td>
    </tr>

    <tr v-for="item in reportItemsLocalCopy" :key="item.id">
      <ActiveUsersReportGridRowReadOnly :item="item"/>
    </tr>

<!--    <tr class="table-light" v-show="reportItemsLocalCopy.length">-->
<!--      <td>Итого</td>-->
<!--      <td></td>-->
<!--      <td></td>-->
<!--      <td></td>-->
<!--      <td>{{ totalSales }} руб</td>-->
<!--      <td></td>-->
<!--      <td></td>-->
<!--    </tr>-->
    </tbody>
  </table>
</template>

<script>
// import {mapState, mapGetters} from 'vuex';
import ActiveUsersReportGridRowReadOnly from "@/components/ActiveUsersReportGridRowReadOnly";

export default {
  name: 'ActiveUsersReportGrid',

  props: ['items'],

  data() {
    return {
      reportItemsLocalCopy: [],
    };
  },

  components: {
    ActiveUsersReportGridRowReadOnly,
  },

  methods: {},

  watch: {
    items: {
      immediate: true,
      handler  : function ( newVal ) {
        this.reportItemsLocalCopy = newVal || [];
      }
    }
  },

  mounted() {
    this.reportItemsLocalCopy = this.items || [];
  }
}
</script>

