<template>
  <div class="container-fluid">
    <div class="row">
    </div>

    <div class="row">
      <div class="col text-start">
        <div class="card">
          <div class="card-header">
            Период
          </div>
          <div class="card-body">
            <Datepicker v-model="reportDateRange"
                        weekStart="1"
                        range
                        auto-apply
                        showNowButton
                        :enableTimePicker="false"
                        :format="reportDateRangeFormat"
            />
          </div>
        </div>
      </div>
      <div class="col text-start">

        <DistinctItemsCheckboxReportFilter :prefix="'students'" :distinctItems="distinctUsers"
                                           @filter-changed="usersFilterChanged">
          <template v-slot:header-title>
            Студенты
          </template>
          <template #item-label="props">
            Студент #{{ props?.item?.id }}
          </template>
        </DistinctItemsCheckboxReportFilter>

      </div>
      <div class="col text-start">
        <DistinctItemsCheckboxReportFilter :prefix="'authors'" :distinctItems="distinctAuthors"
                                           @filter-changed="authorsFilterChanged">
          <template v-slot:header-title>
            Авторы
          </template>
          <template #item-label="props">
            {{ props?.item?.name }}
          </template>
        </DistinctItemsCheckboxReportFilter>

      </div>
      <div class="col text-start">
        <DistinctItemsCheckboxReportFilter :prefix="'courses'" :distinctItems="distinctCourses"
                                           @filter-changed="coursesFilterChanged">
          <template v-slot:header-title>
            Курсы
          </template>
          <template #item-label="props">
            {{ props?.item?.title }}
          </template>
        </DistinctItemsCheckboxReportFilter>

      </div>

      <div class="col">
        <DistinctItemsCheckboxReportFilter :prefix="'course_price'" :distinctItems="distinctPrices"
                                           @filter-changed="coursePriceFilterChanged">
          <template v-slot:header-title>
            Стоимость
          </template>
          <template #item-label="props">
            {{ props?.item === 0 ? 'бесплатно' : format( props?.item ) }}
          </template>
        </DistinctItemsCheckboxReportFilter>
      </div>

      <div class="col">
        <DistinctItemsCheckboxReportFilter :prefix="'course_lesson_view'" :distinctItems="distinctLessonViewCount"
                                           @filter-changed="lessonViewCountFilterChanged">
          <template v-slot:header-title>
            Уроков просмотрено
          </template>
        </DistinctItemsCheckboxReportFilter>
      </div>

    </div>
    <div class="row py-4">
      <div class="col">
        <AuthorSalesReportGrid :items="filteredReportItems" :totalSales="totalSales"/>
      </div>
    </div>
  </div>

</template>


<script>
/*eslint no-unused-vars: "off"*/

// @ is an alias to /src
import '@vuepic/vue-datepicker/dist/main.css'
import Datepicker from '@vuepic/vue-datepicker';
import AuthorSalesReportGrid from "@/components/AuthorSalesReportGrid";
import _ from 'lodash';
import DistinctItemsCheckboxReportFilter from "@/components/DistinctItemsCheckboxReportFilter";
import authorsService from "@/services/authors-sales-report";
import {formatPrice} from "@/utils/format";

export default {
  name      : 'AuthorsSalesView',
  components: {
    DistinctItemsCheckboxReportFilter,
    AuthorSalesReportGrid,
    Datepicker
  },

  data() {
    return {
      sortedReportItems: [],

      reportDateRange: null,

      reportFilters: {
        authors         : [],
        allAuthorsToggle: false,

        courses         : [],
        allCoursesToggle: false,

        users         : [],
        allUsersToggle: true,

        price          : [],
        lessonViewCount: [],
      }

    }
  },

  computed: {

    allUsersFilterChecked() {
      return this.reportFilters.users.length === this.distinctUsers.length;
    },

    allAuthorsFilterChecked() {
      return this.reportFilters.authors.length === this.distinctAuthors.length;
    },

    allCoursesFilterChecked() {
      return this.reportFilters.courses.length === this.distinctCourses.length;
    },

    totalSales() {

      return _.reduce( this.filteredReportItems, ( sum, item ) => {
        return sum + (item.paymentTransaction?.total || 0)
      }, 0 )

    },

    filteredByUsers() {
      console.time( 'filteredByUsers' );

      let items = _.chain( this.sortedReportItems )
          .filter( item => !!item.user )
          .value();

      var filtered = _.chain( items )
          .filter( item => this.reportFilters.users.map( u => u.id ).includes( item.user.id ) )
          .value();

      console.timeEnd( 'filteredByUsers' );
      return filtered;
    },

    filteredByAuthors() {
      console.time( 'filteredByAuthors' );

      let filtered = _.chain( this.filteredByUsers )
          .filter( item => !!item.course )
          .filter( item => !!item.course.author )
          .filter( item => this.reportFilters.authors.map( a => a.id ).includes( item.course.author.id ) )
          .value();

      console.timeEnd( 'filteredByAuthors' );
      return filtered;
    },

    filteredByCourses() {
      console.time( 'filteredByCourses' );

      if (this.filteredByAuthors.length === 0) return [];

      var filtered = _.chain( this.filteredByAuthors )
          .filter( item => !!item.course )
          .filter( item => this.reportFilters.courses.map( co => co.id ).includes( item.course.id ) )
          .value();

      console.timeEnd( 'filteredByCourses' );

      return filtered;
    },

    filteredByPrice() {
      console.time( 'filteredByPrice' );

      if (this.filteredByCourses.length === 0) return [];

      console.time( 'filtered1' );
      var filtered1 = _.chain( this.filteredByCourses ).filter( item => !!item.course );
      console.timeEnd( 'filtered1' );

      console.time( 'filtered2' );
      var filtered2 = filtered1.filter( item => this.reportFilters.price.includes( item.course.price ) )
      console.timeEnd( 'filtered2' );

      console.time( 'value' );
      var value = filtered2.value();
      console.timeEnd( 'value' );

      console.timeEnd( 'filteredByPrice' );
      return value;
    },

    filteredByViewCount() {
      console.time( 'filteredByViewCount' );

      if (this.filteredByPrice.length === 0) return [];

      var filtered = _.chain( this.filteredByPrice )
          .filter( item => !!item.course )

          .filter( item => this.reportFilters.lessonViewCount.includes( item.course.progress.lessonsCompleted ) )
          .value();

      console.timeEnd( 'filteredByViewCount' );

      return filtered;
    },

    filteredReportItems() {
      return this.filteredByViewCount;
    },

    distinctUsers() {

      let items = _.chain( this.sortedReportItems )
          .filter( item => !!item.user )
          .uniqBy( item => item.user.id )
          .map( item => item.user )
          .value();

      return _.sortBy( items, item => item.id );
    },

    distinctAuthors() {

      let items = _.chain( this.filteredByUsers )
          .map('course.author')
          .uniqBy('id')
          .sortBy('name')
          .value();

      return items;
    },

    distinctPrices() {
      let items2 = _.chain( this.filteredByCourses )
          .filter( item => !!item.course )
          .uniqBy( item => item.course.price )
          .map( item => item.course.price )
          .value();

      return _.sortBy( items2, item => item );
    },

    distinctLessonViewCount() {
      let items2 = _.chain( this.filteredByCourses )
          .filter( item => !!item.course )
          .uniqBy( item => item.course.progress.lessonsCompleted )
          .map( item => item.course.progress.lessonsCompleted )
          .value();

      return _.sortBy( items2, item => item );
    },

    distinctCourses() {
      let items2 = _.chain( this.filteredByAuthors )
          .filter( item => !!item.course )
          .uniqBy( item => item.course.id )
          .map( item => item.course )
          .value();

      return _.sortBy( items2, item => item?.title );
    },

  },

  watch: {
    sortedReportItems: {
      immediate: true,
      handler  : function () {
        this.reportFilters.users = this.distinctUsers;
        this.reportFilters.authors = this.distinctAuthors;
        this.reportFilters.courses = this.distinctCourses;
      }
    },

    reportDateRange( newVal ) {
      var [from, to] = newVal;
      this.searchSalesReportItems( from, to );
    }
  },

  methods: {

    format( number ) {
      return formatPrice( number )
    },

    coursePriceFilterChanged( payload ) {
      this.reportFilters.price = payload;
    },

    lessonViewCountFilterChanged( payload ) {
      this.reportFilters.lessonViewCount = payload;
    },

    coursesFilterChanged( payload ) {
      this.reportFilters.courses = payload;
    },

    authorsFilterChanged( payload ) {
      this.reportFilters.authors = payload;
    },

    usersFilterChanged( payload ) {
      this.reportFilters.users = payload;
    },

    toggleAllCoursesFilter() {
      if (this.allCoursesFilterChecked === false) {
        this.reportFilters.courses = this.distinctCourses;
        return;
      }

      if (this.allCoursesFilterChecked === true) {
        this.reportFilters.courses = [];
        return;
      }
    },

    toggleAllAuthorsFilter() {
      if (this.allAuthorsFilterChecked === false) {
        this.reportFilters.authors = this.distinctAuthors;
        return;
      }

      if (this.allAuthorsFilterChecked === true) {
        this.reportFilters.authors = [];
        return;
      }
    },

    toggleAllUsersFilter() {
      if (this.allUsersFilterChecked === false) {
        this.reportFilters.users = this.distinctUsers;
        return;
      }

      if (this.allUsersFilterChecked === true) {
        this.reportFilters.users = [];
        return;
      }
    },

    reportDateRangeFormat( date ) {
      var [from, to] = date;
      const formatDate = ( date ) => new Intl.DateTimeFormat( 'ru-RU' ).format( date );
      return [`${ formatDate( from ) } - ${ formatDate( to ) }`];
    },

    resetReport() {
      this.sortedReportItems = [];
    },

    async searchSalesReportItems( dateFrom, dateTo ) {
      this.resetReport();
      var response = await authorsService.find( { dateFrom, dateTo } );
      this.sortedReportItems = (response?.data || []);
    },
  },

  mounted() {
  }
}
</script>
